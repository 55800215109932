@import "../../style.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,800;1,900&display=swap');

.search {
  @include themify($themes) {
    height: 100vh;
    width: 100vw;
    background-image: url("../bluecow.svg");
    color: themed("textColor");
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-family: "Poppins", sans-serif;

    .search__field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5vh;
      background-color: transparent;
      color: themed("textColor");
      top: 0;
      z-index: 1000;
      position: relative;
      //gap: 8vw;

      .back{

        position: fixed;
        left: 8vw;
        
        a{
          text-decoration: none;
          color: inherit;
        }
      }

      .search__input {
        border: none;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
        height: 100%;
        flex: 1;

        input{
          border: none;
          outline: none;
          background-color: transparent;
          font-size: 15px;          
        }

        input:focus {
          outline: none;
        }
      }
    }

    .search__results {
      padding: 20px;

      ul {
        list-style: none;

        li {
          padding: 3vw;
          color: black;
          text-decoration: none;
          align-items: center;
          background-color: #F7FEFF;
          width: 80vw;
          height: 6vh;
          border-radius: 3vw;
        }

        a{
          text-decoration: none;
          color: black;
        }
      }
    }
  }
}
