@import "../../style.scss";

.comments {
  @include themify($themes) {
    .write{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin: 20px 0px;
      flex: 3;

      span{
        font-weight: 700;
        font-size: 15px;
      }

      input{
        flex:5;
        padding: 10px;
        border: 1px solid themed("border");
        background-color: transparent;
        color: themed("textColor");
        border-radius: 2vw;
        font-family: "Poppins", sans-serif;
      }

      input:focus{
        outline: none;
      }

    }

    .comment {
      margin: 30px 0px;
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .info {
        flex:5;
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: flex-start;

        span {
          font-weight: 700;
          font-size: 15px;
        }

        p{
          color:themed("textColorSoft")
        }
      }

      .date{
        flex:1;
        align-self: center;
        color: gray;
        font-size: 10px;
      }
    }
  }
}