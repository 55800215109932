@import "../../style.scss";

.posts {
    @include themify($themes) {

    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 2vw;
    background-color: transparent;

    @include mobile {
        padding: 2vw;
        background-color: transparent;
        
    }
}
}